import { FunctionComponent, ReactNode } from 'react';
import { Text } from '@templafy/ui/components/text/Text';
import { Center } from '@templafy/ui/components/center/Center';
import { Cover } from '@templafy/ui/components/cover/Cover';
import { Stack } from '@templafy/ui/components/stack/Stack';
import { IconTemplafy } from '@templafy/ui/icons/IconTemplafy';
import { FlexBox } from '@templafy/ui/components/flexBox/FlexBox';
import { useTranslator } from '@templafy/translation';

import { TemplafyLogoProduct } from '@templafy/ui/illustrations/TemplafyLogoProduct';
import styles from './StandalonePage.module.scss';
import { GlobalFeatureFlags } from '@/generated/FeatureManagement';

export type StandalonePageProps = {
  children?: ReactNode;
  title: string;
} & Pick<IllustrationProps, 'landscapeImage' | 'portraitImage'>;

export const StandalonePage: FunctionComponent<StandalonePageProps> = ({
  title,
  children,
  landscapeImage,
  portraitImage,
}) => {
  return (
    <Cover
      padding="0"
      className={
        GlobalFeatureFlags.TemplafyRebrand
          ? styles.background
          : styles.oldBackground
      }
    >
      <Cover.Center>
        <Center maxWidth="1600">
          <FlexBox className={styles.standalonePage}>
            <TemplafyLink />
            <Illustration
              landscapeImage={landscapeImage}
              portraitImage={portraitImage}
            />
            <Content title={title}>{children}</Content>
          </FlexBox>
        </Center>
      </Cover.Center>
    </Cover>
  );
};

interface ContentProps {
  children?: ReactNode;
  title: string;
}

const Content: FunctionComponent<ContentProps> = ({ title, children }) => {
  return (
    <Cover minHeight="100%" gap="400">
      <Cover.Center>
        <Center testId="here" maxWidth="1200">
          <Stack gap="300">
            <Text as="h1" testId="title" size="500">
              {title}
            </Text>
            {children}
          </Stack>
        </Center>
      </Cover.Center>
    </Cover>
  );
};

interface IllustrationProps {
  landscapeImage: string;
  portraitImage: string;
}

const Illustration: FunctionComponent<IllustrationProps> = ({
  landscapeImage,
  portraitImage,
}) => {
  return (
    <>
      <img
        data-testid="illustration"
        className={styles.illustrationLandscape}
        src={landscapeImage}
        alt=""
      />
      <img
        data-testid="illustration"
        className={styles.illustrationPortrait}
        src={portraitImage}
        alt=""
      />
    </>
  );
};

interface TemplafyLinkProps {
  link?: string;
}

const TemplafyLink: FunctionComponent<TemplafyLinkProps> = ({ link }) => {
  const translator = useTranslator();
  return (
    <a
      className={styles.templafyLink}
      href={link}
      aria-label={translator.visitTemplafyWebsiteLabel()}
    >
      <TemplafyLogoProduct
        width={150}
        data-testid="templafyLogo"
        className={styles.templafyLogo}
      />
      <IconTemplafy
        testId="iconTemplafy"
        className={styles.templafyIcon}
        size="large"
        theme="primary"
      />
    </a>
  );
};
