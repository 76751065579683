import { FunctionComponent } from 'react';
import { IconTemplafy } from '@templafy/ui/icons/IconTemplafy';
import { TemplafyLogoProduct } from '@templafy/ui/illustrations/TemplafyLogoProduct';
import { usePageOrientation } from '../../../utilities/usePageOrientation';
import styles from './TemplafyIcon.module.scss';

export const TemplafyIcon: FunctionComponent = () => {
  const isLandscape = usePageOrientation();
  return (
    <div
      className={
        isLandscape ? styles.templafyIconLandscape : styles.templafyIconPortrait
      }
    >
      {isLandscape ? (
        <IconTemplafy testId="iconTemplafy" theme="primary" size="large" />
      ) : (
        <TemplafyLogoProduct width={150} />
      )}
    </div>
  );
};
