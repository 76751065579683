/* eslint-disable */
/* File is auto generated by TypeScriptGenerator version 9.5.5 - don't edit */
const globalSystemName = "global";
const localSystemName = "authentication";

const LocalFeatureFlags = {
    
};

const GlobalFeatureFlags = {
    EngageSign: false,
    LibraryHiveRibbon: false,
    FontDistributor: false,
    OfflineInstallation: false,
    AddInOptimizedAuthenticationFlow: false,
    LibraryAppLandingPage: false,
    LibraryManagementLandingPage: false,
    EnableDataProviders: false,
    UiComponentsExperimentalDialog: false,
    AiAssistantExcelAddIn: false,
    AiAssistantInAppMarketing: false,
    CustomDataConnector: false,
    ProductivityEnableSettingsInCheck: false,
    TemplafyRebrand: false,
    Automated3RdPartyPackageSecurityCheck: false,
    FilterEditorDialogImprovement: false,
    UserProfileRequiredFieldsCommunication: false,
    LibraryContentInsights: false,
    UniversalAiAssistant: false
};

interface IEnabledFeatureFlags {
    [systemName: string]: string[];
}

const getEnabledFeatureFlags = () => {
    const enabledFeatureFlagsBase64 = document.querySelector<HTMLMetaElement>('meta[name="enabledFeatureFlags"]')?.content;
    if (enabledFeatureFlagsBase64 == null) {
        return {} as IEnabledFeatureFlags;
    }

    const enabledFeatureFlagsJson = atob(enabledFeatureFlagsBase64);
    return JSON.parse(enabledFeatureFlagsJson) as IEnabledFeatureFlags;
}

const enabledFeatureFlags = getEnabledFeatureFlags();

enabledFeatureFlags[globalSystemName]?.forEach((name: string) => {
    (GlobalFeatureFlags as any)[name] = true;
});

enabledFeatureFlags[localSystemName]?.forEach((name: string) => {
    (LocalFeatureFlags as any)[name] = true;
});

export { GlobalFeatureFlags, LocalFeatureFlags };
